import { ValidationError } from 'yup';

export const getErrors = (err: unknown) => {
  if (err instanceof ValidationError) {
    return err.errors;
  } else if (err instanceof Error) {
    return [err.message];
  } else {
    return ['Unknown error'];
  }
};
