export const cancelReasons = [
  { key: 'cost', value: 'of high cost for the client' },
  { key: 'no_response', value: 'the member did not book or respond' },
  { key: 'incorrect_details', value: 'the referral has incorrect details' },
  {
    key: 'seen_elsewhere',
    value: 'your patient informed us that they have been seen elsewhere',
  },
  {
    key: 'no_wish_to_proceed',
    value:
      'your patient informed us that they do not wish to proceed with your referral',
  },
  {
    key: 'referral_out_of_scope',
    value: 'the referral falls outside of our scope of practice',
  },
];
