import { InferType, mixed, object, string } from 'yup';
import { EmailEventRecordType } from '../types';
import { IObject } from './IObject';

export const EmailEventSchema = object().shape({
  RecordType: mixed<EmailEventRecordType>().required(),
  referralId: string(),
});

export type EmailEventData = InferType<typeof EmailEventSchema>;

export type EmailEvent = IObject.HasIdTimestamped<EmailEventData>;
