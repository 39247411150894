import { ProfessionalInfo } from '../types/ProfessionalInfo';

const professionalsProd: Record<string, Omit<ProfessionalInfo, 'id'>> = {
  'beverley-longhurst': {
    fullname: 'Beverley Longhurst',
    services: ['75', '76', '3'],
  },
  'chloe-cruickshank': {
    fullname: 'Chloe Cruickshank',
    services: [
      '7',
      /*'86'*/
    ],
  },
  'dr-anne-williams': { fullname: 'Dr. Anne Williams', services: ['88'] },
  'dr-isabel-white': { fullname: 'Dr. Isabel White', services: ['6'] },
  'dr-lisa-dvorjetz': { fullname: 'Dr. Lisa Dvorjetz', services: ['5'] },
  'dr-lucy-davidson-': { fullname: 'Dr. Lucy Davidson', services: ['5'] },
  'dr-simone-ruddick': { fullname: 'Dr. Simone Ruddick', services: ['5'] },
  'georgina-giebner': { fullname: 'Georgina Giebner', services: ['1'] },
  'hannah-leach': { fullname: 'Hannah Leach', services: ['4'] },
  'helen-oleary': { fullname: "Helen O'Leary", services: ['91'] },
  'jo-cunningham': { fullname: 'Jo Cunningham', services: ['1'] },
  'joanna-injore': { fullname: 'Joanna Injore', services: ['1'] },
  'johanna-bowie': { fullname: 'Johanna Bowie', services: ['3', '7' /*'82'*/] },
  'kat-tunnicliffe': { fullname: 'Katherine Tunnicliffe', services: ['4'] },
  'kati-bori': { fullname: 'Kati Bori', services: ['78', '79'] },
  'ass-prof-laura-ashurst': {
    fullname: 'Ass. Prof. Laura Ashurst',
    services: ['75'],
  },
  'lucy-allen': { fullname: 'Lucy Allen', services: ['4'] },
  'lynn-buckley': { fullname: 'Lynn Buckley', services: ['6', '7'] },
  'lynn-de-domball': { fullname: 'Lynn De Domball', services: ['4'] },
  'marianne-spiteri': {
    fullname: 'Marianne Spiteri',
    services: ['76', '79' /*'84' */],
  },
  'mary-woods': { fullname: 'Mary Woods', services: ['88'] },
  'nevo-burrell': { fullname: 'Nevo Burrell', services: ['78'] },
  'nichola-williams': { fullname: 'Nichola Williams', services: ['1'] },
  'nick-michell': { fullname: 'Nick Michell', services: ['93'] },
  'rachel-white': { fullname: 'Rachel White', services: ['1'] },
  'rebecca-van-klinken': { fullname: 'Rebecca van Klinken', services: ['4'] },
  'reta-sowton': { fullname: 'Reta Sowton', services: ['7'] },
  'rocio-herrero-garcia': {
    fullname: 'Rocío Herrero García',
    services: ['91'],
  },
  'ruth-mead-wellings': { fullname: 'Ruth Mead-Wellings', services: ['74'] },
  'stephanie-yin': { fullname: 'Stephanie Yin', services: ['94'] },
  'vicky-fox': { fullname: 'Victoria Fox', services: ['75'] },
  'victoria-nelson': { fullname: 'Victoria Nelson', services: ['1'] },
  'lisa-punt': { fullname: 'Lisa Punt', services: ['3'] },
  'dr-margarita-chacin': {
    fullname: 'Dr. Margarita Chacin Fuenmayor',
    services: ['5'],
  },
  'pauline-mcculloch': { fullname: 'Pauline McCulloch', services: ['1'] },
  'diana-bromboszcz': { fullname: 'Diana Bromboszcz', services: ['4'] },
  'laura-hope-stone': { fullname: 'Laura Hope-Stone', services: ['5'] },
  'dr-sharon-cook': { fullname: 'Dr. Sharon Cook', services: ['5'] },
  'dr-rachel-foxwell': { fullname: 'Dr Rachel Foxwell', services: ['5'] },
  'anne-brewer': { fullname: 'Anne Brewer', services: ['7'] },
  'samantha-holmes': { fullname: 'Samantha Holmes', services: ['74'] },
  'dr-ellie-cavalli': { fullname: 'Dr Ellie Cavalli', services: ['5'] },
  'lucy-tennant': { fullname: 'Lucy Tennant', services: ['74'] },
  'claire-brett-pitt': {
    fullname: 'Claire Brett-Pitt',
    services: ['75', '93'],
  },
  // 'georgina-bates': { fullname: 'Georgina Bates', services: ['80'] }, // Commented due the disabled service
  'megan-pattwell': { fullname: 'Megan Pattwell', services: ['1'] },
  'alysia-burrows': { fullname: 'Alysia Burrows', services: ['1'] },
  'anna-howells': { fullname: 'Anna Howells', services: ['96'] },
  'rebecca-brennan': { fullname: 'Rebecca Brennan', services: ['4'] },
  'julia-pugh': { fullname: 'Julia Pugh', services: ['6', '94'] },
  'louise-fairburn': { fullname: 'Louise Fairburn', services: ['5'] },

  // The next records are no longer valid
  // 'dr-claire-taylor': { fullname: 'Dr. Claire Taylor', services: ['7', '77'] },
  // 'claire-moore-': { fullname: 'Claire Moore', services: ['1'] },
  // 'teresa-day': { fullname: 'Teresa Day', services: ['1'] },
  // 'amelia-cook': { fullname: 'Amelia Cook', services: ['7', '83'] },
  // 'louise-malone': { fullname: 'Louise Malone', services: ['4'] },
};

const professionalsDev: Record<string, Omit<ProfessionalInfo, 'id'>> = {
  // Dietitian
  'dr-paddy-rehill': { fullname: 'Dr. Paddy Rehill', services: ['1'] },
  'dr-doctor-test1': { fullname: 'Dr. Doctor Test1', services: ['1'] },
  // Hypnotherapy
  'matthew-fenwick': { fullname: 'Matthew Fenwick', services: ['76'] },
  // Psychosexual therapist
  'elle-mckeever': { fullname: 'Elle McKeever', services: ['6'] },
};

export const professionals = {
  dev: professionalsDev,
  prod: professionalsProd,
};
