import { IFirebase } from '../types';

const instanceOfTimestamp = (
  instance: any,
): instance is IFirebase.Timestamp => {
  return (
    instance &&
    typeof instance === 'object' &&
    'seconds' in instance &&
    'nanoseconds' in instance
  );
};

const convertTimestampsToDates = (
  firestoreObject?: Record<string, unknown> | null,
) => {
  if (!firestoreObject) {
    return firestoreObject;
  }

  const convertedObject = { ...firestoreObject };
  for (const [key, value] of Object.entries(firestoreObject)) {
    // convert inner objects
    if (value && typeof value === 'object') {
      convertedObject[key] = convertTimestampsToDates(
        value as Record<string, unknown>,
      );
    }

    // convert simple properties
    if (instanceOfTimestamp(value)) {
      convertedObject[key] = value.toDate();
    }

    if (value && Array.isArray(value)) {
      convertedObject[key] = value.map((item) => {
        if (!item) {
          return item;
        }

        if (instanceOfTimestamp(item)) {
          return item.toDate();
        } else if (item && typeof item === 'object') {
          return convertTimestampsToDates(item);
        } else {
          return item;
        }
      });
    }
  }

  return convertedObject;
};

const isGoogleSnapshotDoc = (
  doc: IFirebase.QuerySnapshotDoc,
): doc is IFirebase.GoogleQuerySnapshotDoc => {
  return typeof doc.exists === 'boolean';
};

export function normalizeDoc<T>(doc: IFirebase.QuerySnapshotDoc) {
  if (isGoogleSnapshotDoc(doc) && !doc.exists) {
    return null;
  }
  if (!isGoogleSnapshotDoc(doc) && !doc.exists()) {
    return null;
  }

  return convertTimestampsToDates({
    id: doc.id,
    ...doc.data(),
  }) as T;
}

export function normalizeDocs<T>(docs: Array<IFirebase.QuerySnapshotDoc>) {
  return docs.map(
    (doc) =>
      convertTimestampsToDates({
        id: doc.id,
        ...doc.data(),
      }) as T,
  );
}
