import { object, string, InferType, number, array } from 'yup';
import { PatientSchema } from '../PatientSchema';
import { LandgCaseManagerSchema } from '../LandgCaseManagerSchema';
import { IObject } from '../IObject';
import { BaseReferralSchema } from './BaseReferralSchema';

const BaseLandgReferralSchemaObject = {
  patient: PatientSchema,
  joinReasonId: number().required(),
  caseManager: LandgCaseManagerSchema,
  gip: object().shape({
    code: string().required('GIP code is required'),
  }),
  documents: object().shape({
    latestClinicalReport: object()
      .shape({
        name: string().required('Latest clinical report is required'),
        path: string().required(),
      })
      .required(),
    consent: object()
      .shape({
        name: string().required('Consent form is required'),
        path: string().required(),
      })
      .required(),
    referrals: array()
      .of(
        object().shape({
          name: string().required('Document name is required'),
          path: string().required(),
        }),
      )
      .max(20, 'Maximum can be uploaded 20 documents'),
  }),
};

export const CreateLandgReferralSchema = object().shape(
  BaseLandgReferralSchemaObject,
);

// As it is stored in the database
export const LandgReferralSchema = BaseReferralSchema.shape({
  ...BaseLandgReferralSchemaObject,
});

export type CreateLandgReferral = InferType<typeof CreateLandgReferralSchema>;
export type LandgReferralData = InferType<typeof LandgReferralSchema>;
export type LandgReferral = IObject.HasIdTimestamped<LandgReferralData>;
