import { InferType, bool, object } from 'yup';

export const OrganizationEmailOptionsSchema = object().shape({
  skipReminderOne: bool(),
  skipReminderTwo: bool(),
});

export type OrganizationEmailOptions = InferType<
  typeof OrganizationEmailOptionsSchema
>;
