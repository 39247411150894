import { emailValidation } from '@percihealth/core-js';
import { InferType, boolean, object } from 'yup';
import { IObject } from './IObject';

export const UserSchema = object().shape({
  email: emailValidation.required('Referrer email is required'),
  expert: boolean(),
  admin: boolean(),
  roleEditor: boolean(),
});

export type UserData = InferType<typeof UserSchema>;
export type User = IObject.HasIdTimestamped<UserData>;
