import {
  emailValidation,
  nameValidation,
  phoneValidation,
} from '@percihealth/core-js';
import { InferType, object } from 'yup';

export const PatientSchema = object().shape({
  firstname: nameValidation.required('Patient first name is required'),
  lastname: nameValidation.required('Patient surname is required'),
  email: emailValidation.required('Patient email is required'),
  phone: phoneValidation,
});

export type Patient = InferType<typeof PatientSchema>;
