import { array, boolean, date, number, object, string } from 'yup';

export const AppointmentConclusionSchema = object().shape({
  date: date(),
  notesToPatient: string(),
  // Optionally a doctor can refer (suggest) an appointment to a specialist.
  // The specialist should approve it and then manually go to Healee and create an appointment.
  // https://miro.com/app/board/uXjVMyBaM2M=/
  requestedReferral: object()
    .shape({
      services: array().of(number()),
      notes: string(), // doctor notes
      followUpRequired: boolean(),
      withinWeeks: string(),
      // Review is done in EBP and is not sent to Referrals
      // review: AppointmentReferralReviewSchema.nullable().optional(),
    })
    .default(undefined),
});
