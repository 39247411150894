import { ServiceInfo } from '@packages/core-shared/types/ServiceInfo';
import { useEffect, useState } from 'react';

export const useFetchServices = () => {
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState<Record<string, ServiceInfo>>({});

  const [error, setError] = useState<string | undefined>(undefined);

  // Services
  useEffect(() => {
    const load = async () => {
      try {
        setError(undefined);
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/services`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );

        if (!response.ok) {
          setError('Failed to fetch services');
        } else {
          const data = await response.json();
          setServices(data);
        }
      } catch (error) {
        console.error(error);
        setError('Failed to fetch services');
      } finally {
        setLoading(false);
      }
    };
    load();
  }, []);

  return { loading, services, error };
};
