import { emailValidation, nameValidation } from '@percihealth/core-js';
import { InferType, object } from 'yup';

export const LandgCaseManagerSchema = object().shape({
  email: emailValidation.required('Case manager email is required'),
  firstname: nameValidation.required('Case manager first name is required'),
  lastname: nameValidation.required('Case manager last name is required'),
});

export type LandgCaseManager = InferType<typeof LandgCaseManagerSchema>;
