import { emailValidation } from '@percihealth/core-js';
import { array, date, InferType, number, object, string } from 'yup';
import { AppointmentConclusionSchema } from './AppointmentConclusionSchema';

export const AppointmentDataSchema = object().shape({
  id: string(),
  status: string().required().min(3).max(50),
  services: array().of(number()),
  referralId: string().max(30),
  patientEmail: emailValidation,
  date: date(),
  previousAppointmentDates: array()
    .of(
      object().shape({
        date: date(),
        rescheduled: date(),
        by: string(),
      }),
    )
    .optional(),
  doctor: string().max(150),
  conclusion: AppointmentConclusionSchema.default(undefined),
  cancelledAt: date(),
});

export type AppointmentData = InferType<typeof AppointmentDataSchema>;
