import { InferType, mixed, object, string } from 'yup';
import { ReferralStatus, ReferralType } from '../../types';
import { CancelOptionsSchema } from '../CancelOptionsSchema';
import { OrganizationEmailOptionsSchema } from '../OrganizationEmailOptionsSchema';
import { AppointmentDataSchema } from '../AppointmentSchema';
import { IObject } from '../IObject';

const ReferralEmailLineSchema = object().shape({ status: string() });
type ReferralEmailLine = InferType<typeof ReferralEmailLineSchema>;

// Fields that are used in all referrals (Default, Healix, L&G)
export const BaseReferralSchema = object().shape({
  type: mixed<ReferralType>().oneOf(Object.values(ReferralType)),
  status: mixed<ReferralStatus>()
    .oneOf(Object.values(ReferralStatus))
    .required(),
  source: string(),

  cancelReason: object()
    .shape({
      key: string().required(),
      value: string().required(),
    })
    .default(undefined),
  cancelOptions: CancelOptionsSchema,
  service: object()
    .shape({
      id: string(),
      name: string().required(),
      healee_ref: string().required(),
      professional_type: string().required(),
    })
    .default(undefined),

  emails: object<Record<string, ReferralEmailLine>>(),
  emailOptions: OrganizationEmailOptionsSchema,
  // latest appointment
  appointment: AppointmentDataSchema,
});

export type BaseReferralData = InferType<typeof BaseReferralSchema>;
export type BaseReferral = IObject.HasIdTimestamped<BaseReferralData>;

export const UpdateReferralSchema = BaseReferralSchema.pick(['emailOptions']);
export type UpdateReferral = InferType<typeof UpdateReferralSchema>;
