const dateTimeOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
};

export const dateToLondonDateTimeString = (date?: Date) => {
  return date
    ? date.toLocaleDateString('en-GB', {
        ...dateTimeOptions,
        timeZone: 'Europe/London',
      })
    : '';
};

export const dateToLocaleDateTimeString = (date?: Date) => {
  return date ? date.toLocaleDateString('en-GB', dateTimeOptions) : '';
};

export const latestDayTime = (date: Date) =>
  new Date(date.setHours(23, 59, 59, 999));
export const earliestDayTime = (date: Date) =>
  new Date(date.setHours(0, 0, 0, 0));
